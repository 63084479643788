<template>
    <div style="display: flex; flex-wrap: wrap; gap: 15px; max-width: 100vw">
        <v-select
            v-model="_companySelected"
            return-object
            style="max-width: 500px;"
            :items="companies"
            attach
            :label="textLabel || $t('companies.selectCompany')"
            item-text="attributes.name"
            item-value="id"
            clearable
        />

        <div style="max-width: 150px;"
             v-if="!this.$vuetify.breakpoint.mobile"
        >
            <v-img
                v-if="_companySelected"
                :src="getImagePath()"
                height="60px"
                contain
                class="my-1 logo-effect"
            />
        </div>
    </div>
</template>

<script>
import CompaniesAPIService from "@/services/CompaniesAPIService";

export default {
    name: "CompanySelector",
    props: {
        companyIdSelected: {
            required: false
        },
        textLabel: {
            type: String,
            required: false
        }
    },
    data: function () {
        return {
            companies: [],
            companySelected: null
        }
    },
    computed: {
        _companySelected: {
            get() {
                if (this.companyIdSelected) {
                    if (!this.companySelected || this.companySelected.id != this.companyIdSelected) {
                        return this.companies.find(company => this.companyIdSelected == company.id);
                    }
                }
                return this.companySelected;
            },
            set(value) {
                this.companySelected = value;
                this.$emit('select:company', this.companySelected);
            }
        }
    },
    async created() {
        await this.fetchCompanies();
    },
    methods: {
        async fetchCompanies() {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const result = await CompaniesAPIService.getCompanies({signal});

                this.companies = result.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        getImagePath() {
            const image = this._companySelected?.attributes?.logo ?? '';

            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/' + image : '';
        }
    }
}
</script>
